.scroll-container {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: hsl(0 0% 50%);
}

.ant-table {
    /* overflow-y: hidden; */

}

.ant-modal-content,
.ant-modal-header {
    border-radius: 3px !important;
}


.desktop-table .ant-table-cell {
    font-size: 12.5px !important;
}