
  .active {
    color: rgb(41, 216, 41);
    
  }
  .accept {
    color: #1890ff;
    
  }
  .create {
    color: #41b11f;
    
  }
  .button {
    margin-right: 16px !important;
    padding: 0px;
    column-gap: 0px !important;
    
  }