.logo {
  max-width: 100%;
  max-height: 100%;
  padding: 8px;
}
.box {
  padding-left: 20px ;
}

.box:hover {
  background-color: #f0f0f0;
}