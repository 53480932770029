.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}
.title {
  margin-right: auto;
  color: red;
}

.mainContainer {
  padding: 24px;
  background-color: white;
}
.filtersContainer {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
}

.buttonContainer {
  width: 150px;
  padding: 10px;
}
