.notactive {
    color: red;
    
  }
  .active {
    color: red;
    
  }

  .accept {
    color: #389e0d;
    
  }
  .create {
    color: #1890ff;
    
  }