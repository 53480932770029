.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.title {
  margin-right: auto;
}

.deleteButton:hover,
.deleteButton:focus-within {
  border-color: red;
}
.text-preview {
  max-height: 3.2em; /* Высота, равная двум строкам */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничение до двух строк */
  -webkit-box-orient: vertical;
  white-space: normal;
}
